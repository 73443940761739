
class Graduation {
  constructor () {
    this.id = null
    this.name = null
    this.date = null
    this.intake = null
    this.comment = null
    this.state = null
  }

  toString () {
    return `Graduation{id=${this.id}, name='${this.name}', intake='${this.intake}', comment='${this.comment}', intake='${this.intake}'}`
  }
}

export default Graduation
