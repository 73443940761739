<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="graduations"
      hide-default-footer
      sort-by="number"
      class="elevation-1 ml-4"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Graduations</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="1200px" persistent>
            <template v-slot:activator="{ on }">
              <v-btn color="green" dark class="mb-2" v-on="on" v-if="showCreate">Add Graduation</v-btn>
            </template>
            <v-card>
              <validation-observer ref="form">
                <form @submit.prevent="onSubmit">
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="4" md="4">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="Graduation Name"
                      >
                      <v-text-field
                        v-model="editedGraduation.name"
                        label="Name"
                        outlined
                        name="Graduation Name"
                        :error-messages="errors"
                        color="grey"
                        dense
                        solo
                      />
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="Graduation Name"
                      >
                      <v-select
                          :items="intakes"
                          item-text="name"
                          item-value="id"
                          v-model="editedGraduation.intake"
                          :error-messages="errors"
                          dense
                          return-object
                          label="Select Intake"
                          outlined
                          color="grey"
                          solo
                        />
                      </validation-provider>
                    </v-col>
                    <v-col
                    cols="12"
                    sm="4"
                    md="4"
                  >
                  <v-menu
                    ref="graduationDateMenu"
                    v-model="graduationDateMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <validation-provider
                      v-slot="{ errors }"
                      rules="required"
                      name="Graduation Date"
                    >
                      <v-text-field
                        v-model="formattedGraduationDate"
                        label="Graduation Date"
                        outlined
                        name="Graduation Date"
                        :error-messages="errors"
                        color="grey"
                        readonly
                        dense
                        solo
                        v-on="on"
                      ></v-text-field>
                      </validation-provider>
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="graduationDate"
                      min="1900-01-01"
                      @change="saveGraduationDate"
                      no-title
                    ></v-date-picker>
                  </v-menu>
                  </v-col>
                    <v-col cols="12" sm="4" md="4">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="Graduation Name"
                      >
                      <v-select
                          :items="statuses"
                          item-text="name"
                          item-value="id"
                          v-model="editedGraduation.state"
                          dense
                          :error-messages="errors"
                          label="Select Status"
                          outlined
                          color="grey"
                          solo
                        />
                      </validation-provider>
                    </v-col>
                    <v-col cols="12" sm="4" md="8">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="Graduation Description"
                      >
                      <v-text-field
                        v-model="editedGraduation.comment"
                        label="Comment"
                        outlined
                        name="Graduation Comment"
                        :error-messages="errors"
                        color="grey"
                        dense
                        solo
                      >
                      </v-text-field>
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-btn class="success ml-4 mb-4" text type="submit">Save</v-btn>
                <v-btn class="mb-4" color="blue darken-1" text @click="close">Cancel</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
              </form>
              </validation-observer>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <!-- <template v-slot:item.name="{ item }">
        <p @click="loadSingleGraduation(item)" class="btn blue--text">{{ item.name }}</p>
      </template> -->
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editGraduation(item)"
          v-if="showEdit"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click.stop="graduationDialog(item)"
          v-if="showDelete"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <v-dialog v-model="deleteDialog" hide-overlay max-width="500px">
    <v-card tile outlined>
        <v-card-text>
          <p>Are you sure you want to delete this Graduation ?</p>
          <v-btn link small color="blue" @click="deleteGraduation">Yes</v-btn>
          <v-btn link small color="blue" @click="deleteDialog = false">No</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import Graduation from '@/models/Graduation'
export default {
  data: () => ({
    privileges: null,
    showEdit: false,
    showDelete: false,
    showCreate: false,
    dialog: false,
    deleteDialog: false,
    graduationIndex: null,
    graduationItem: null,
    headers: [
      {
        text: 'No',
        align: 'start',
        sortable: true,
        value: 'number'
      },
      { text: 'Graduation Name', value: 'name' },
      { text: 'Graduation date', value: 'date' },
      { text: 'Intake', value: 'intake.name' },
      { text: 'Center', value: 'intake.center' },
      { text: 'Status', value: 'state' },
      { text: 'Comment', value: 'comment' },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    graduations: [],
    editedGraduationIndex: -1,
    editedGraduation: new Graduation(),
    defaultItem: new Graduation(),
    intakes: [],
    statuses: ['OPEN', 'CLOSED'],
    graduationDate: null,
    retrievedGraduationDate: null,
    graduationDateMenu: false
  }),

  computed: {
    formTitle () {
      return this.editedGraduationIndex === -1 ? 'New Graduation' : 'Edit Graduation'
    },
    formattedGraduationDate () {
      return this.graduationDate ? this.$moment(this.graduationDate).format('DD-MMM-YYYY')
        : this.retrievedGraduationDate ? this.$moment(this.retrievedGraduationDate).format('DD-MMM-YYYY')
          : ''
    },
    ...mapState('store', ['snackbar', 'color', 'text', 'timeout', 'currentUser', 'currentCenter'])
  },
  mounted () {
    this.privileges = this.currentUser.privileges
    this.checkPrivileges()
  },

  watch: {
    dialog (val) {
      val || this.close()
    }
  },

  created () {
    this.loadGraduations()
    this.loadIntakes()
  },

  methods: {
    ...mapMutations('store', {
      setSnackbar: 'SET_SNACKBAR',
      setColor: 'SET_COLOR',
      setText: 'SET_TEXT',
      setStoreGraduation: 'SET_STORE_GRADUATION'
    }),
    loadGraduations () {
      this.$delinquentService.fetchGraduations(null, null, 'OPEN', null, null)
        .then(response => {
          if (!response.status) {
            this.graduations = response.content
            this.graduations.forEach(element => {
              element.number = this.graduations.indexOf(element) + 1
            })
          } else {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    loadIntakes () {
      this.$delinquentService.fetchIntakes('OPEN')
        .then(response => {
          if (!response.status) {
            this.intakes = response
          } else {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },

    editGraduation (item) {
      this.editedGraduationIndex = this.graduations.indexOf(item)
      this.graduationItem = item
      this.$delinquentService.fetchGraduationById(item.id, null)
        .then(response => {
          if (response.id) {
            this.editedGraduation = response
            this.retrievedGraduationDate = response.date
          } else {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
      this.dialog = true
    },
    loadSingleGraduation (item) {
      this.editedGraduationIndex = this.graduations.indexOf(item)
      this.graduationItem = item
      this.$delinquentService.fetchGraduationById(item.id, 'graduands', 0, 20)
        .then(response => {
          if (response.id) {
            response.graduands.content.forEach(element => {
              element.names = element.delinquent.firstName + ' ' + element.delinquent.lastName
              element.receiverNames = element.receiver.firstName + ' ' + element.receiver.lastName
              element.trade = element.trainingEnrolments == null ? '' : element.trainingEnrolments[0].trainingClass.training.name
              element.delinquent.age = parseInt(this.$moment(new Date().toISOString().substr(0, 10)).format('YYYY')) - parseInt(this.$moment(element.delinquent.dateOfBirth).format('YYYY'))
            })
            this.setStoreGraduation(response)
            this.$router.push('/graduation/single-graduation')
          } else {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    graduationDialog (item) {
      this.graduationIndex = this.graduations.indexOf(item)
      this.graduationItem = item
      this.deleteDialog = true
      this.alertDialog = false
      this.alert = false
    },

    deleteGraduation () {
      this.deleteDialog = false
      this.$delinquentService.deleteGraduation(this.graduationItem.id)
        .then(response => {
          if (response.status === 'success') {
            this.loadGraduations()
            // this.graduations.splice(this.graduationIndex, 1)
          } else {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },

    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedGraduation = Object.assign({}, this.defaultItem)
        this.editedGraduationIndex = -1
      }, 300)
    },
    onSubmit () {
      this.$refs.form.validate().then(success => {
        if (!success) {
          return
        }
        if (this.editedGraduationIndex > -1) {
          this.updateGraduation()
        } else this.createGraduation()
        this.$nextTick(() => {
          this.$refs.form.reset()
        })
      })
    },
    updateGraduation () {
      this.editedGraduation.date = this.formattedGraduationDate
      const deletedGraduationArray = this.graduations.splice(this.editedGraduationIndex, 1)
      this.$delinquentService.saveGraduation(this.editedGraduation)
        .then(response => {
          if (response.id) {
            this.loadGraduations()
            // this.graduations.splice(this.editedGraduationIndex, 1, response)
            // this.graduations[this.editedGraduationIndex].number = this.graduationItem.number
            this.close()
          } else {
            this.graduations.splice(this.editedGraduationIndex, 0, deletedGraduationArray[0])
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    createGraduation () {
      this.editedGraduation.date = this.formattedGraduationDate
      this.editedGraduation.intake = {
        id: this.editedGraduation.intake.id
      }
      this.$delinquentService.saveGraduation(this.editedGraduation)
        .then(response => {
          if (!response.status) {
            // this.graduations.push(response)
            this.loadGraduations()
            this.close()
          } else {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response)
          }
        })
    },
    saveGraduationDate (graduationDate) {
      this.$refs.graduationDateMenu.save(graduationDate)
    },
    checkPrivileges () {
      this.privileges.indexOf('CREATE_GRADUATIONS') > -1 || this.privileges.indexOf('ALL_AUTHORITIES') > -1 ? this.showCreate = true : this.showCreate = false
      this.privileges.indexOf('UPDATE_GRADUATIONS') > -1 || this.privileges.indexOf('ALL_AUTHORITIES') > -1 ? this.showEdit = true : this.showEdit = false
      this.privileges.indexOf('DELETE_GRADUATIONS') > -1 || this.privileges.indexOf('ALL_AUTHORITIES') > -1 ? this.showDelete = true : this.showDelete = false
    }
  }
}
</script>
<style scoped>
.btn {
  cursor: pointer;
}
</style>
